import axios from "axios";

const tgBotInstance = axios.create({
  baseURL: 'https://poly-web.ru:5000',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
  proxy: {
    host: 'https://poly-web.ru',
    port: 5000,
    protocol: 'https',
  },
});

export default tgBotInstance;