import React from 'react';
import classes from './style.module.scss';
import Title from 'shared/ui/Title';
import Subtitle from 'shared/ui/Subtitle';
import Paragraph from 'shared/ui/Paragraph';

const HistoryBlock = () => {
  return (
    <div className={'container'}>
      <div className={classes['history-container']}>
        <Title>история()</Title>
        <div className={classes['first-row']}>
          <Subtitle style={{ padding: '0 0 40px 0' }}>
            История студенческой веб-студии началась много лет назад, когда
            несколько студентов университета, увлеченных веб-разработкой, начали
            работать над первыми проектами.
          </Subtitle>
          <Paragraph>
            В начале студенческая веб-студия была маленькой командой из
            нескольких человек, работавших над проектами в свободное время после
            университетских занятий. Но по мере того, как они продолжали
            развиваться и создавать новые проекты, репутация студии начала
            расти, и они стали получать все больше заказов.
          </Paragraph>
        </div>
        <div className={classes['second-row']}>
          <Subtitle style={{ padding: '0 0 40px 0' }}>
            Со временем студенческая веб-студия начала расширяться, нанимая
            новых сотрудников и улучшая свои услуги.
          </Subtitle>
          <Paragraph>
            Благодаря высокому качеству работы и надежности, которую они
            предоставляли своим клиентам, они привлекали все больше и больше
            заказов. Они также начали работать со стартапами и местными
            бизнесами, которые были заинтересованы в создании своих собственных
            веб-сайтов и приложений.
          </Paragraph>
        </div>
        <div className={classes['third-row']}>
          <Subtitle style={{ padding: '0 0 40px 0' }}>
            Сегодня студенческая веб-студия продолжает свой успех, работая над
            проектами различных масштабов и сложности.
          </Subtitle>
          <Paragraph>
            Они зарекомендовали себя как профессиональные и надежные
            веб-разработчики, предоставляющие своим клиентам высококачественные
            услуги и инновационные решения. Их история создания студенческой
            веб-студии является ярким примером того, что мечты и увлечения могут
            стать успешным бизнесом.
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default HistoryBlock;
